import styles from './Error.module.css';

const Error = props => {
	const message = props.children ? props.children : 'There was an error';
	const errorCode = props.errorCode ? <p className={styles.Code}>{`Error code: ${props.errorCode}`}</p> : null

	return (
		<div className='alert alert-danger'> 
			<p className='mb-0'>{`Error: ${message}`}</p>
			{errorCode}
		</div>
	)
}

export default Error;