// HELPER FUNCTION
const isJson = data => {
	try {
        JSON.parse(data);
    } catch (error) {
        return false;
    }
    return true;
}

// FETCH
export const fetchData = async url => {
	const response = await fetch(
		url, 
		{
			headers: {
				'Content-Type': 'application/json'
			}
		}
	);

	const resdata = await response.json();
	const jsData = isJson(resdata) ? await JSON.parse(resdata) : resdata;

	if(!response.ok){	
		console.log('Not OK')
		return {
			ok: false,
			message: jsData.code
		}
	}	

	return {
		ok: true,
		data: jsData
	}
}

// POST
export const postData = async (url, data) => {

	try {
  	const response = await fetch(url,{
      method: 'POST',
      body: JSON.stringify({...data}),
      headers: {
        'Content-Type': 'application/json',
      }
    });

    const resdata = await response.json();
		const jsData = isJson(resdata) ? await JSON.parse(resdata) : resdata;

    if(!response.ok){
			return {
				ok: false,
				error: { message: jsData.error.message, code: jsData.error.code}
			}
		}

		return {
			ok: true,
			data: jsData
		}

  } catch (error) {
  	console.log(error)
  	return(error);
  }
}